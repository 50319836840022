import { Table, Button, Input } from "antd";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { useState, useEffect, useCallback } from "react";
import config from "../components/header/config";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words"; // Highlighter import
import * as XLSX from "xlsx";


const AksiyonPlaniRapor = () => {
  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const filteredData = data.filter(
        (item) => item.status === "2" || item.status === "-2"
      );
      const updatedData = filteredData.map((item) => ({
        ...item,
        durum: item.status === "2" ? "Onaylandı" : "Reddedildi",
      }));
      setDatas(updatedData);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/aksiyon`, setDatas);
  }, [fetchData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleExport = () => {
    const filteredData = datas.map((item) => ({
      Aksiyon: item.aksiyon,
      AksiyonTipi: item.aksiyontipi,
      TalepEden: item.talepeden,
      Maliyet: item.maliyet,
      Aciklama: item.aciklama,
      AksiyonTanimi: item.aksiyontanimi,
      Sorumlu: item.sorumlu,
      SorumluDep: item.sorumludep,
      Butce: item.butce,
      EtkiYuzdesi: item.etkiyuzdesi,
      Periyodik: item.periyodik,

      TekrarSuresi: item.tekrarsuresi,
      Bilgilenecek: item.bilgilenecek,
      Baslangic: item.datebas,
      Bitis: item.datebit,
     
      Key: item.key,
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Aksiyon Planı Rapor");
    const columnWidths = [
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 150 },
      { wpx: 100 },
      { wpx: 150 },
      { wpx: 200 },
      { wpx: 150 },
    ];

    worksheet["!cols"] = columnWidths;
    XLSX.writeFile(workbook, "AksiyonPlani.xlsx");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Aksiyon",
      dataIndex: "aksiyon",
      key: "aksiyon",
      ...getColumnSearchProps("aksiyon"),
    },
    {
      title: "Aksiyon Tipi",
      dataIndex: "aksiyontipi",
      key: "aksiyontipi",
      ...getColumnSearchProps("aksiyontipi"),
    },
    {
      title: "Talep Eden",
      dataIndex: "talepEden",
      key: "talepEden",
      ...getColumnSearchProps("talepEden"),
    },
    {
      title: "Maliyet",
      dataIndex: "maliyet",
      key: "maliyet",
      ...getColumnSearchProps("maliyet"),
    },
    {
      title: "Açıklama",
      dataIndex: "aciklama",
      key: "aciklama",
      ...getColumnSearchProps("aciklama"),
    },
    {
      title: "Aksiyon Tanımı",
      dataIndex: "aksiyontanimi",
      key: "aksiyontanimi",
      ...getColumnSearchProps("aksiyontanimi"),
    },
    {
      title: "Sorumlu",
      dataIndex: "sorumlu",
      key: "sorumlu",
      ...getColumnSearchProps("sorumlu"),
    },
    {
      title: "Sorumlu Departman",
      dataIndex: "sorumludep",
      key: "sorumludep",
      ...getColumnSearchProps("sorumludep"),
    },
    {
      title: "butce",
      dataIndex: "butce",
      key: "butce",
      ...getColumnSearchProps("butce"),
    },
    {
      title: "Etki Yüzdesi",
      dataIndex: "etkiyuzdesi",
      key: "etkiyuzdesi",
      ...getColumnSearchProps("etkiyuzdesi"),
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      align: "center",
      ...getColumnSearchProps("key"),
    },
    {
      title: "Periyodik",
      dataIndex: "periyodik",
      key: "periyodik",
      align: "center",
      ...getColumnSearchProps("periyodik"),
    },
    {
        title: "Tekrar Süresi",
        dataIndex: "tekrarsuresi",
        key: "tekrarsuresi",
        align: "center",
        ...getColumnSearchProps("tekrarsuresi"),
      },
      {
        title: "Bilgilenecek",
        dataIndex: "bilgilenecek",
        key: "bilgilenecek",
        align: "center",
        ...getColumnSearchProps("bilgilenecek"),
      },
      {
        title: "Başlangıç",
        dataIndex: "datebas",
        key: "datebas",
        align: "center",
        ...getColumnSearchProps("datebas"),
      },
      {
        title: "Bitiş",
        dataIndex: "datebit",
        key: "datebit",
        align: "center",
        ...getColumnSearchProps("datebit"),
      },
    {
      title: "Hedef Silme",
      dataIndex: "sil",
      key: "sil",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleSil(record._id)}>
          Sil
        </Button>
      ),
    },
  ];

  const handleSil = async (id) => {
    console.log("Sil id:", id);
    try {
      const response = await fetch(`${config.API_URL}/delete/aksiyon`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        console.log("Data saved successfully");
        setDatas((prevDatas) => prevDatas.filter((item) => item._id !== id));
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa  flex justify-normal">
        <div className="SolMenu">
          <div>
            <SolMenu></SolMenu>{" "}
          </div>
        </div>
        <div className="px-6 py-6 flex flex-col">
          <Button
            type="primary"
            style={{ marginBottom: "16px", width: "200px" }} // Buton genişliğini ayarlayın
            onClick={handleExport}
          >
            Excel'e Aktar
          </Button>
          <Table
            dataSource={datas}
            columns={columns}
            bordered
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};

export default AksiyonPlaniRapor;
