import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Modal,
  Table,  
} from "antd";
import config from "../components/header/config";

const generateRandomKey = () => {
  const randomString = () =>
    Math.random().toString(36).substring(2, 6).toUpperCase();
  return `KEY-${randomString()}-${randomString()}`;
};

const checkKeyExists = async (key) => {
  try {
    const response = await fetch(`${config.API_URL}/checkKeyExists`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ key }),
    });

    if (!response.ok) {
      throw new Error(`Sunucu hatası: ${response.statusText}`);
    }

    const data = await response.json();
    return data.exists;
  } catch (error) {
    console.error("Anahtar kontrol hatası:", error);
    return false;
  }
};

const AksiyonPlani = () => {
  const [form] = Form.useForm();
  const [sorumlular, setSorumlular] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aksiyonlar, setAksiyonlar] = useState([]);
  const [modalForm] = Form.useForm();

  

  const [generatedKey, setGeneratedKey] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
    generateAndCheckKey(); // Modal açıldığında anahtar üret ve kontrol et
  };

  const generateAndCheckKey = async () => {
    let key;
    let keyExists = true;

    while (keyExists) {
      key = generateRandomKey();
      keyExists = await checkKeyExists(key); // Anahtarın daha önce kullanılıp kullanılmadığını kontrol et
    }

    setGeneratedKey(key); // Kullanılmamış anahtar bulunursa set et
    modalForm.setFieldsValue({ key }); // Form içinde key alanını doldur
  };

  const handleOk = () => {
    modalForm
      .validateFields()
      .then((values) => {
        const aksiyon = {
          ...values,
          datebas: values.datebas.format("YYYY-MM-DD"),
          datebit:values.datebit.format("YYYY-MM-DD"),
        };
        setAksiyonlar([...aksiyonlar, aksiyon]);
        modalForm.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => { 
    fetchData(`${config.API_URL}/list/sorumlu`, setSorumlular);
    
  }, [fetchData]);

  const handleSubmit = async (values) => {
    for (const aksiyon of aksiyonlar) {
      const aksiyonplani = { ...values, ...aksiyon, status: "2" };
      try {
        const response = await fetch(`${config.API_URL}/addaksiyonplani`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(aksiyonplani),
        });

        if (response.ok) {
          console.log("Data saved successfully");
          form.resetFields();
          setAksiyonlar([]);
        } else {
          console.error("Failed to save data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  

  const columns = [
    { title: "Aksiyon Tanımı", dataIndex: "aksiyontanimi", key: "aksiyontanimi" },
    { title: "Sorumlu", dataIndex: "sorumlu", key: "sorumlu" },
    { title: "Sorumlu Departman", dataIndex: "sorumludep", key: "sorumludep" },
    { title: "Bütçe", dataIndex: "butce", key: "butce" },   
    { title: "Etki Yüzdesi", dataIndex: "etkiyuzdesi", key: "etkiyuzdesi" },
    { title: "Periyodik Mi", dataIndex: "periyodik", key: "periyodik" },
    { title: "Tekrar Süresi", dataIndex: "tekrarsuresi", key: "tekrarsuresi" },
    { title: "Bigilenecekler", dataIndex: "bilgilenecek", key: "bilgilenecek" },
    { title: "Başlangıç Tarihi", dataIndex: "datebas", key: "datebas" },
    { title: "Bitiş Tarihi", dataIndex: "datebit", key: "datebit" },
    { title: "Key", dataIndex: "key", key: "key" },
  ];

  return (
    <>
      <Header />
      <div className="Anasayfa flex justify-normal">
        <SolMenu />
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical px-3"
          className="w-full"
        >
        

          
        <Form.Item
                label="Aksiyon Planı"
                name="aksiyon"
                rules={[
                  { required: true, message: "Lütfen Aksiyon Planı Giriniz" },
                ]}
              >
                <Input placeholder="Aksiyon Planı Giriniz" className="w-full" />
              </Form.Item>

              <Form.Item
              label="Aksiyon Tipi"
              name="aksiyontipi"
              rules={[
                {
                  required: true,
                  message: "Lütfen Aksiyon Tipi Giriniz",
                },
              ]}
            >
              <Select placeholder="Aksiyon Tipi Giriniz">
                <Select.Option value={"Genel"}>Genel</Select.Option>
                <Select.Option value={"Musteri"}>Müşteri</Select.Option>
                <Select.Option value={"Tedarikci"}>Tedarikçi</Select.Option>
                <Select.Option value={"Urun"}>Ürün</Select.Option>
              </Select>
              </Form.Item>

              <Form.Item
                label="Talep Eden"
                name="talepEden"
                rules={[{ required: true, message: "Lütfen Talep Eden Giriniz" }]}
              >
                <Select placeholder="Talep Eden Giriniz" className="w-full">
                  {sorumlular.map((sorumlu, i) => (
                    <Select.Option key={i} value={sorumlu.ad}>
                      {sorumlu.ad}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Maliyet"
                name="maliyet"
                rules={[{ required: true, message: "Lütfen Maliyet Giriniz" }]}
              >
   <InputNumber
    className="w-full"
    placeholder="Maliyet Giriniz"
    min={0} // Minimum değer (negatif değerleri engeller)
    step={0.01} // Ondalık sayılar için adım değeri
    formatter={(value) => 
      `₺ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    parser={(value) => 
      value.replace(/₺\s?|(,*)/g, '')
    }
  />
              </Form.Item>

              <Form.Item
                label="Açıklama"
                name="aciklama"
                rules={[
                  { required: true, message: "Lütfen Açıklama Giriniz" },
                ]}
              >
                <Input placeholder="Açıklama Giriniz" className="w-full" />
              </Form.Item>



          <Button type="primary" onClick={showModal}>
            Aksiyon Kalemi Ekle
          </Button>

          <Table dataSource={aksiyonlar} columns={columns} rowKey="aksiyon" />

          <Modal
            title="Aksiyon Tanımı"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={modalForm} layout="vertical" className="w-full">
              <Form.Item label="Anahtar" name="key">
                <Input value={generatedKey} readOnly />
              </Form.Item>

              <Form.Item
                label="Aksiyon Tanımı"
                name="aksiyontanimi"
                rules={[
                  { required: true, message: "Lütfen Aksiyon Tanımı Giriniz" },
                ]}
              >
                <Input placeholder="Aksiyon Tanımı Giriniz" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Sorumlu"
                name="sorumlu"
                rules={[{ required: true, message: "Lütfen Sorumlu Giriniz" }]}
              >
                <Select placeholder="Sorumlu Giriniz" className="w-full">
                  {sorumlular.map((sorumlu, i) => (
                    <Select.Option key={i} value={sorumlu.ad}>
                      {sorumlu.ad}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Sorumlu Departman"
                name="sorumludep"
                rules={[{ required: true, message: "Lütfen Sorumlu Departman Giriniz" }]}
              >
                <Input placeholder="Sorumlu Departman Giriniz" className="w-full" />
              </Form.Item>


              <Form.Item
                label="Bütçe"
                name="butce"
                rules={[{ required: true, message: "Lütfen Bütçe Giriniz" }]}
              >
                  <InputNumber
    className="w-full"
    placeholder="Bütçe Giriniz"
    min={0} // Minimum değer (negatif değerleri engeller)
    step={0.01} // Ondalık sayılar için adım değeri
    formatter={(value) => 
      `₺ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    parser={(value) => 
      value.replace(/₺\s?|(,*)/g, '')
    }
  />
              </Form.Item>

              <Form.Item
                label="Etki Yüzdesi"
                name="etkiyuzdesi"
                rules={[{ required: true, message: "Etki Yüzdesi Giriniz" }]}
              >
        <InputNumber
    className="w-full"
    placeholder="Etki Yüzdesi Giriniz"
    min={0}
    max={100} // Maksimum yüzde 100
    step={0.1} // Ondalık hassasiyet
    formatter={(value) => `%${value}`}
    parser={(value) => value.replace('%', '')}
    onChange={(value) => {
      
      // Değer 3 basamağı geçerse inputu kilitle
      if (value && value.toString().length > 3) {
        return false;
      }
    }}
  />
              </Form.Item>

              <Form.Item
              label="Periyodik Mi?"
              name="periyodik"
              rules={[
                {
                  required: true,
                  message: "Lütfen Periyodik Giriniz",
                },
              ]}
            >
              <Select placeholder="Periyodik Giriniz">
                <Select.Option value={"Evet"}>Evet</Select.Option>
                <Select.Option value={"Hayır"}>Hayir</Select.Option>
               
              </Select>
              </Form.Item>

              <Form.Item
                label="Tekrar Süresi"
                name="tekrarsuresi"
                rules={[{ required: true, message: "Tekrar Süresi Giriniz" }]}
              >
                <InputNumber placeholder="Tekrar Süresi Giriniz" className="w-full" />
              </Form.Item>

              
              <Form.Item
                label="Bilgilenecek"
                name="bilgilenecek"
                rules={[{ required: true, message: "Lütfen Bilgilenecek Giriniz" }]}
              >
                <Select placeholder="Bilgilenecek Giriniz" className="w-full">
                  {sorumlular.map((sorumlu, i) => (
                    <Select.Option key={i} value={sorumlu.ad}>
                      {sorumlu.ad}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Başlangıç Tarihi"
                name="datebas"
                rules={[
                  { required: true, message: "Lütfen Başlangıç Tarihi  Giriniz" },
                ]}
              >
                <DatePicker
                  placeholder="Başlangıç Tarihi Giriniz"
                  className="w-full"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
              <Form.Item
                label="Bitiş Tarihi"
                name="datebit"
                rules={[
                  { required: true, message: "Lütfen Bitiş Tarihi Giriniz" },
                ]}
              >
                <DatePicker
                  placeholder="Bitiş Tarihi Giriniz"
                  className="w-full"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Form>
          </Modal>

          <Button htmlType="submit" type="primary">
            Kaydet
          </Button>
        </Form>
      </div>
    </>
  );
};

export default AksiyonPlani;
