import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Anasayfa } from "./pages/Anasayfa";
import StratejikHedef from "./pages/StratejikHedef";
import AksiyonPlani from "./pages/AksiyonPlani";
import AksiyonPlaniRapor from "./pages/AksiyonPlaniRapor";
import StratejikHedefRapor from "./pages/StratejikHedefRapor";
import RiskAnaliz from "./pages/RiskAnaliz";
import RiskAnalizRapor from "./pages/RiskAnalizRapor";
import { Bildirimler } from "./pages/Bildirimler";
import { UcretYonetimi } from "./pages/UcretYonetimi";
import { KontrolNoktalari } from "./pages/KontrolNoktalari";
import { Departman } from "./pages/SistemAltyapi/Departman";
import { Sorumlu } from "./pages/SistemAltyapi/Sorumlu";
import { Derece } from "./pages/SistemAltyapi/Derece";
import { Kpi } from "./pages/SistemAltyapi/Kpi";
import { HedefTuru } from "./pages/SistemAltyapi/HedefTuru";
import DepartmanEkle from "./pages/SistemAltyapi/DepartmanEkle";
import Giris from "./pages/Giris";
import DepartmanGuncelle from "./pages/SistemAltyapi/DepartmanGuncelle";
import DereceEkle from "./pages/SistemAltyapi/DereceEkle";
import HedefTuruEkle from "./pages/SistemAltyapi/HedefTuruEkle";
import KpiEkle from "./pages/SistemAltyapi/KpiEkle";
import SorumluEkle from "./pages/SistemAltyapi/SorumluEkle";
import DereceGuncelle from "./pages/SistemAltyapi/DereceGuncelle";
import KpiGuncelle from "./pages/SistemAltyapi/KpiGuncelle";
import HedefTuruGuncelle from "./pages/SistemAltyapi/HedefTuruGuncelle";
import SorumluGuncelle from "./pages/SistemAltyapi/SorumluGuncelle";
import KontrolNoktalariEkle from "./pages/KontrolNoktalariEkle";
import KontrolNoktalariGuncelle from "./pages/KontrolNoktalariGuncelle";
import UcretYonetimiEkle from "./pages/UcretYonetimiEkle";
import UcretYonetimiGuncelle from "./pages/UcretYonetimiGuncelle";
import Istatistikler from "./pages/Istatistikler";
import StratejikHedefGuncelle from "./pages/StratejikHedefGuncelle";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Giris />} />
        <Route path="/Anasayfa" element={<Anasayfa />} />
        <Route path="/StratejikHedef" element={<StratejikHedef />} />
        <Route path="/AksiyonPlani" element={<AksiyonPlani />} />
        <Route path="/AksiyonPlaniRapor" element={<AksiyonPlaniRapor />} />
        <Route path="/StratejikHedefRapor" element={<StratejikHedefRapor />} />
        <Route path="/RiskAnaliz" element={<RiskAnaliz />} />
        <Route path="/RiskAnalizRapor" element={<RiskAnalizRapor />} />
        <Route path="/Bildirimler" element={<Bildirimler />} />
        <Route path="/UcretYonetimi" element={<UcretYonetimi />} />
        <Route path="/UcretYonetimi/Ekle" element={<UcretYonetimiEkle />} />
        <Route
          path="/UcretYonetimi/Guncelle/:id"
          element={<UcretYonetimiGuncelle />}
        />
        <Route path="/KontrolNoktalari" element={<KontrolNoktalari />} />
        <Route
          path="/KontrolNoktalari/Ekle"
          element={<KontrolNoktalariEkle />}
        />
        <Route
          path="/KontrolNoktalari/Guncelle/:id"
          element={<KontrolNoktalariGuncelle />}
        />
        <Route path="/Departman" element={<Departman />} />
        <Route path="/Sorumlu" element={<Sorumlu />} />
        <Route path="/Derece" element={<Derece />} />
        <Route path="/KPI" element={<Kpi />} />
        <Route path="/HedefTuru" element={<HedefTuru />} />
        <Route path="/Departman/Ekle" element={<DepartmanEkle />} />
        <Route path="/Derece/Ekle" element={<DereceEkle />} />
        <Route path="/HedefTuru/Ekle" element={<HedefTuruEkle />} />
        <Route path="/KPI/Ekle" element={<KpiEkle />} />
        <Route path="/Sorumlu/Ekle" element={<SorumluEkle />} />
        <Route path="/Departman/Guncelle/:id" element={<DepartmanGuncelle />} />
        <Route path="/KPI/Guncelle/:id" element={<KpiGuncelle />} />
        <Route path="/HedefTuru/Guncelle/:id" element={<HedefTuruGuncelle />} />
        <Route path="/Derece/Guncelle/:id" element={<DereceGuncelle />} />
        <Route path="/Sorumlu/Guncelle/:id" element={<SorumluGuncelle />} />
        <Route path="/Istatistikler" element={<Istatistikler />} />
        <Route
          path="/StratejikHedef/Guncelle/:id"
          element={<StratejikHedefGuncelle />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
